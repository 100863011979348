import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const NavBar = () => {
    const Container = styled.div`
      margin-bottom: 100px;
    `;

    const NavBarStyle = styled.div`
      width: 100vw;
      height: 80px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;

      h1 {
        font-size: 24px;
        font-weight: 600;
      }

      button {
        width: 100px;
        height: 40px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    `;

    const navigate = useNavigate();

    return (
        <Container>
            <NavBarStyle>
                <button onClick={ () => {
                    navigate('/');
                } }>Go Back
                </button>
                <h1>Framer Motion</h1>
                <button onClick={ () => {
                    navigate(0);
                } }>Reload
                </button>
            </NavBarStyle>
        </Container>
    );
};