import {motion} from "framer-motion"

export const TextAnimation = () => {
    return (
        <motion.h1 initial={{x: -100}}
                   animate={{
                       x: [0, 900, 0],
                   }}
                   transition={{
                       duration: 3,
                       delay: 0.2,
                   }}
                   whileHover={{
                       scale: 0.9,
                       opacity: 0.2,
                   }}>
            It is H1 for animation using framer motion.
        </motion.h1>
    );
};