import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TextAnimation } from './pages/animations/TextAnimation';
import { Main } from './pages/Main';
import { NavBar } from './components/NavBar';
import { ExitEnterAnimation } from './pages/animations/ExitEnterAnimation';
import { DragAnimation } from './pages/animations/DragAnimation';
import { HoverTapAnimation } from './pages/animations/HoverTapAnimation';
import { ScrollAnimation } from './pages/animations/ScrollAnimation';
import { TriggeredAnimation } from './pages/animations/TriggeredAnimation';
import { PageTransitionAnimation, PageTransitionAnimationAbout } from './pages/animations/PageTransitionAnimation';

function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <Routes>
                <Route path={ '/' } element={ <Main /> } />
                <Route path={ '/text-animation' } element={ <TextAnimation /> } />
                <Route path={ '/exit-enter-animation' } element={ <ExitEnterAnimation /> } />
                <Route path={ '/drag-animation' } element={ <DragAnimation /> } />
                <Route path={ '/hover-tab-animation' } element={ <HoverTapAnimation /> } />
                <Route path={ '/scroll-animation' } element={ <ScrollAnimation /> } />
                <Route path={ '/triggered-animation' } element={ <TriggeredAnimation /> } />
                <Route path={ '/page-transition-animation' } element={ <PageTransitionAnimation /> } />
                <Route path={ '/page-transition-animation/about' } element={ <PageTransitionAnimationAbout /> } />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
