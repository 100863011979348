import styled from 'styled-components';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const Text = styled.div`
  margin-top: 30px;
`;

const Motion = styled(motion.div)`
  width: 100px;
  height: 100px;
  background-color: blue;
`;

const Button = styled.button`
  padding: 12px 18px;
`;

export const ExitEnterAnimation = () => {
    const [ show, setShow ] = useState<boolean>(true);

    return (
        <Container>
            <Text>Framer Motion Tutorial: Exit and enter animation</Text>
            <AnimatePresence>
                {
                    show && (
                        <Motion
                            initial={ {
                                opacity: 0,
                                x: 50,
                            } }
                            animate={ {
                                opacity: 1,
                                x: 100,
                            } }
                            exit={ {
                                opacity: 0,
                                x: -150,
                            } }
                            transition={ {
                                duration: 1,
                            } }>
                        </Motion>
                    )
                }
            </AnimatePresence>
            <Button onClick={ () => setShow(!show) }>{ show ? 'Remove Box' : 'Add Box' }</Button>
        </Container>
    );
};