import { Card } from '../components/Card';

export const Main = () => {
    return (
        <>
            <Card title={ 'Text Animation' } path={ '/text-animation' } />
            <Card title={ 'Exit and Enter Animation' } path={ '/exit-enter-animation' } />
            <Card title={ 'Drag Animation' } path={ '/drag-animation' } />
            <Card title={ 'Hover and Tab Animation' } path={ '/hover-tab-animation' } />
            <Card title={ 'Scroll Animation' } path={ '/scroll-animation' } />
            <Card title={ 'Triggered Animation' } path={ '/triggered-animation' } />
            <Card title={ 'Page Transition Animation' } path={ '/page-transition-animation' } />
        </>
    );
};