import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: grey;
`;

const Motion = styled(motion.div)`
  background-color: #fff;
  padding: 3rem 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const TextMotion = styled(motion.h3)`
  width: 600px;
`;

export const HoverTapAnimation = () => {
    const [ showCard, setShowCard ] = useState<boolean>(false);

    return (
        <Container>
            <Motion whileHover={ {
                scale: 1.1,
            } }
                    transition={ {
                        layout: {
                            duration: 0.3,
                            type: 'spring',
                        },
                    } }
                    layout
                    onClick={ () => setShowCard(!showCard) }>
                <motion.h3>Hover or Click</motion.h3>
                {
                    showCard && (
                        <TextMotion
                            initial={ {
                                opacity: 0,
                            } }
                            animate={ {
                                opacity: 1,
                            } }>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur cum deserunt eos impedit
                            odit perferendis quaerat rem, rerum sapiente, sit soluta, suscipit. Ea enim ipsa magnam maxime
                            repellendus, voluptates. Omnis.
                        </TextMotion>
                    )
                }
            </Motion>
        </Container>
    );
};