import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export const PageTransitionAnimation = () => {
    const Container = styled(motion.div)`
      color: blue;
      font-size: 30px;
      padding: 20px;
    `;

    return (
        <>
            <PageTransitionAnimationNav />
            <Container
                initial={ {
                    opacity: 0,
                } }
                animate={ {
                    opacity: 1,
                } }
                exit={ {
                    opacity: 0,
                } }
                transition={ {
                    duration: 2,
                } }>
                Home Page for Transition
            </Container>
        </>
    );
};

export const PageTransitionAnimationNav = () => {
    return (
        <nav>
            <ul className={ 'nav' }>
                <li className={ 'nav-item' }>
                    <Link to={ '/page-transition-animation' } className={ 'nav-link' }>
                        Home
                    </Link>
                    <Link to={ '/page-transition-animation/about' } className={ 'nav-link' }>
                        About
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export const PageTransitionAnimationAbout = () => {
    const Container = styled(motion.div)`
      color: green;
      font-size: 30px;
      padding: 20px;
    `;

    return (
        <>
            <PageTransitionAnimationNav />
            <Container
                initial={ {
                    opacity: 0,
                } }
                animate={ {
                    opacity: 1,
                } }
                exit={ {
                    opacity: 0,
                } }
                transition={ {
                    duration: 2,
                } }>
                Home Page for Transition
            </Container>
        </>
    );
};