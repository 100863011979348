import styled from 'styled-components';
import { motion } from 'framer-motion';

export const DragAnimation = () => {
    const Text = styled.h1`
      text-align: center;
    `;

    const Box = styled.div`
      display: flex;
      flex-direction: row;
      gap: 200px;
      align-items: center;
      justify-content: center;
      margin-top: 120px;
    `;

    const Motion = styled(motion.div)`
      width: 100px;
      height: 100px;
      background-color: blue;
    `;

    return (
        <div>
            <Text>Draggable Object with Framer Motion</Text>
            <Box>
                <div>
                    <p>No Constraint</p>
                    <Motion drag>
                    </Motion>
                </div>
                <div>
                    <p>Drag Y Only</p>
                    <Motion drag={ 'y' }>
                    </Motion>
                </div>
                <div>
                    <p>Drag Constraint</p>
                    <Motion
                        drag
                        dragConstraints={ {
                            left: 0,
                            right: 100,
                            top: -100,
                        } }>
                    </Motion>
                </div>
            </Box>
        </div>
    );
};