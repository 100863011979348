import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface CardProps {
    title: string;
    path: string;
}

export const Card = ({ title, path }: CardProps) => {
    const CardStyle = styled.div`
      width: 100vw;
      height: 150px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      padding: 20px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scaleY(1.1);
      }

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      a {
        text-decoration: none;
        color: #000;
      }

      text-decoration: none;
    `;

    const navigate = useNavigate();

    return (
        <CardStyle onClick={ () => {
            navigate(path);
        } }>
            <h1>{ title }</h1>
            <p>{ path }</p>
        </CardStyle>
    );
};