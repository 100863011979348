import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TriggeredAnimation = () => {
    return (
        <Container>
            <h2>React Scroll Animation with Framer Motion</h2>
            <Box />
            <Box />
            <Box />
        </Container>
    );
};

const Box = () => {
    const BoxContainer = styled(motion.div)`
      margin: 200px 0;
      width: 100px;
      height: 100px;
      background-color: blue;
    `;

    return (
        <BoxContainer
            initial={ {
                opacity: 0,
                scale: 0,
                x: 500,
            } }
            whileInView={ {
                opacity: 1, scale: 1,
                x: 0,
            } }
            transition={ {
                duration: 0.6,
            } }
        />
    );
};