import { motion, useScroll } from 'framer-motion';
import styled from 'styled-components';

export const ScrollAnimation = () => {
    const { scrollYProgress } = useScroll();

    const Motion = styled(motion.div)`
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      height: 10px;
      background-color: blue;
      transform-origin: 0;
    `;

    return (
        <>
            <Motion style={ {
                scaleX: scrollYProgress,
            } }>

            </Motion>
            <h2>Scroll Demo</h2>
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
            <p>Scroll Animation Scroll-Linked</p><br />
        </>
    );
};